import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import api from "../api/api";
import { RENEWAL, ROLL_OVER } from "../constants/PolicyConstants";
import useUrlQuery from "../custom-hooks/useUrlQuery";
import {
  setActiveDetails,
  setClaimDetails,
  setPolicyTypeDetails,
  setRegistrationDetails,
  setSavedSegmentId,
  setVehicleDetails,
} from "../modules/input.slice";
import {
  resetIdvRange,
  setBiFuel,
  setConfineToSiteDetails,
  setDiscountCheckboxes,
  setGeographicalExtention,
  setIsFilterLoading,
  setSelectedAddons,
  setSelectedDiscount,
  setShouldShowEditableInputPageOnQuote,
  setelectricalAccessories,
  setnonElectricalAccessories,
} from "../modules/quote.slice";
import { errorHandler } from "../utils/errorHandler";
import { useGetPresetList } from "./dashboard.service";

export const useCreateQuoteMutation = () => {
  const {
    policyTypeDetails,
    vehicleDetails,
    registerationDetails,
    claimDetails,
  } = useSelector((state) => state.input);
  const dispatch = useDispatch();
  const { segment_slug } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const isOnQuotesPage = location.pathname.split("/").includes("quotes");

  const { mutate, isError, isSuccess, data, error, reset, isLoading } =
    useMutation(
      (values) =>
        api.post("process_eligible_products", {
          ...values,
          segment_slug,
        }),
      {
        onSuccess: (res) => {
          queryClient.setQueryData(
            ["lead", res.data.return_data.quote_id],
            () => ({
              ...res,
              data: {
                ...res.data,
                return_data: {
                  ...res.data.return_data,
                  input_data: {
                    policyTypeDetails,
                    vehicleDetails,
                    registerationDetails,
                    claimDetails,
                  },
                },
              },
            })
          );
          if (res.data.status && res.data.status !== 500) {
            dispatch(setShouldShowEditableInputPageOnQuote(false));
            if (isOnQuotesPage) {
              return navigate({
                pathname: "../quotes",
                search: `?traceId=${res.data.return_data.trace_id}`,
              });
            }
            navigate({
              pathname: "./quotes",
              search: `?traceId=${res.data.return_data.trace_id}`,
            });
          }
        },
      }
    );
  const errList = errorHandler(error, data);

  useEffect(() => {
    isSuccess &&
      setInterval(() => {
        reset();
      }, 1000);
  }, [isSuccess]);

  return {
    createQuote: mutate,
    errorMsg: error?.response?.data?.message || error?.message,
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
    isLoading: isLoading,
  };
};
export const useGetLeadData = (proposalApprovalData) => {
  const { segment_slug } = useParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const { presetList, isPresetLoading } = useGetPresetList();
  const trace_id = "";
  const { data } = useQuery(
    ["lead", trace_id, segment_slug],
    () =>
      api.post(`/process_eligible_products`, {
        trace_id,
        segment_slug: segment_slug || proposalApprovalData?.segment_slug,
      }),
    {
      onSuccess: (res) => {
        dispatch(resetIdvRange());
        if (presetList?.status !== "Y" || res.data?.return_data?.discount) {
          dispatch(setSelectedDiscount(res.data?.return_data?.discount));
        }
        dispatch(
          setVehicleDetails({
            fuel_id:
              res?.data?.return_data?.input_data?.vehicleDetails?.fuel_id,
            fuel_type:
              res?.data?.return_data?.input_data?.vehicleDetails?.fuel_type,
            manufacturer_id:
              res?.data?.return_data?.input_data?.vehicleDetails
                ?.manufacturer_id,
            manufacturer_name:
              res?.data?.return_data?.input_data?.vehicleDetails
                ?.manufacturer_name,
            motor_model_id:
              res?.data?.return_data?.input_data?.vehicleDetails
                ?.motor_model_id,
            motor_model_name:
              res?.data?.return_data?.input_data?.vehicleDetails
                ?.motor_model_name,
            motor_variants_id:
              res?.data?.return_data?.input_data?.vehicleDetails
                ?.motor_variants_id,
            version_name:
              res?.data?.return_data?.input_data?.vehicleDetails?.version_name,
          })
        );
        dispatch(
          setRegistrationDetails({
            exshowroom_price:
              res?.data?.return_data?.input_data?.registerationDetails
                ?.exshowroom_price,
            is_vehicle_new:
              res?.data?.return_data?.input_data?.registerationDetails
                ?.is_vehicle_new,
            rto_id:
              res?.data?.return_data?.input_data?.registerationDetails?.rto_id,
            rto_id_data:
              res?.data?.return_data?.input_data?.registerationDetails
                ?.manufacturer_name,
            od_expiry_date:
              res?.data?.return_data?.input_data?.registerationDetails
                ?.od_expiry_date,
            registration_date:
              res?.data?.return_data?.input_data?.registerationDetails
                ?.registration_date,
            tp_expiry_date:
              res?.data?.return_data?.input_data?.registerationDetails
                ?.tp_expiry_date,
          })
        );
        dispatch(
          setPolicyTypeDetails(
            res?.data?.return_data?.input_data?.policyTypeDetails
          )
        );
        dispatch(
          setSavedSegmentId(res?.data?.return_data?.input_data?.segment_slug)
        );
        dispatch(
          setSelectedAddons({
            ...res?.data?.return_data?.addons,
          })
        );
        if (res?.data?.return_data?.discounts) {
          const discounts = res?.data?.return_data?.discounts;
          dispatch(
            setDiscountCheckboxes({
              anti_theft_discount: discounts?.anti_theft,
              handicap_discount_discount: discounts?.handicap_discount,
              aa_membership_discount: discounts?.aa_membership,
              confine_to_site_discount: discounts?.confine_to_site_discount,
              tppd_cover_discount: discounts?.tppd_cover,
            })
          );
          dispatch(setConfineToSiteDetails(discounts?.confine_to_site_details));
        }
        dispatch(
          setGeographicalExtention(
            res?.data?.return_data?.geographical_extension
          )
        );
        dispatch(
          setClaimDetails({
            is_prev_claim:
              res?.data?.return_data?.input_data?.claimDetails?.is_prev_claim,
            prev_ncb:
              res?.data?.return_data?.input_data?.claimDetails?.prev_ncb,
            prev_policy_ic_id:
              res?.data?.return_data?.input_data?.claimDetails
                ?.prev_policy_ic_id,
            prev_policy_ic_id_data:
              res?.data?.return_data?.input_data?.claimDetails
                ?.prev_policy_ic_id_data,
            prev_policy_number:
              res?.data?.return_data?.input_data?.claimDetails
                ?.prev_policy_number,
          })
        );
        if (
          res?.data?.return_data?.input_data?.policyTypeDetails == "Roll Over"
        ) {
          dispatch(setActiveDetails(3));
        } else {
          dispatch(setActiveDetails(2));
        }
      },
      enabled: !isPresetLoading,
    }
  );

  return {
    endpoints: data?.data?.return_data?.quote_data || [],
    vehicleDetails: data?.data?.return_data?.vehicle_details,
    registerationDetails: data?.data?.return_data?.registration_details,
    claimDetails: data?.data?.return_data?.claim_details,
    excess_discount: data?.data?.return_data?.discounts,
    ncb_carry_forward: data?.data?.return_data?.ncb_carryforward,
    imt_23: data?.data?.return_data?.imt_23,
    ncb:
      data?.data?.return_data?.ncb || data?.data?.return_data?.input_data?.ncb,
    ncb_carryforward_amount: data?.data?.return_data?.ncb_carryforward_amount,
    prev_ncb: data?.data?.return_data?.claim_details?.previous_ncb || 0,
    segment_id: data?.data?.return_data?.input_data?.segment_id,
    discount: data?.data?.return_data?.discounts,
    policyType: data?.data?.return_data?.policy_type,
    accessories: data?.data?.return_data?.accessories,
    special_od_discount: data?.data?.return_data?.special_od_discount,
    plan_type_options: data?.data?.return_data?.plan_type_options || [],
    pa_cover:
      data?.data?.return_data?.pa_cover ||
      data?.data?.return_data?.input_data?.paCover,
    countries: data?.data?.return_data?.geographical_extension,
    vehicleTypeSelected: data?.data?.return_data?.vehicle_type,
    selected_IMT: data?.data?.return_data?.selected_imt,
    selected_IDV: data?.data?.return_data?.selected_idv,
    claimInPreviousPolicy:
      data?.data?.return_data?.claim_details?.is_prev_claim === "Y",
    customer_type: data?.data?.return_data?.owner_type,
    selected_prev_ncb: data?.data?.return_data?.selected_prev_ncb,
    addons: data?.data?.return_data?.selected_addons,
    returnData: data?.data?.return_data,
    inputData: data?.data?.return_data,
    is_vehicle_new: data?.data?.return_data?.vehicle_details?.is_vehicle_new,
  };
};

export const useUpdateFilters = (traceId) => {
  const { segment_slug } = useParams();
  const navigate = useNavigate();
  const { getUrlQuery, setUrlQuery } = useUrlQuery();
  const trace_id = getUrlQuery("traceId") || traceId;
  const dispatch = useDispatch();
  // const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    (req) =>
      api.post(`/process_eligible_products`, {
        trace_id,
        segment_slug: segment_slug,
        ...req,
      }),
    {
      onSettled: () => {
        dispatch(setIsFilterLoading(false));
      },
      onSuccess: (res) => {
        navigate({
          search: setUrlQuery("traceId", res.data.return_data.trace_id),
        });
      },
    }
  );
  useEffect(() => {
    if (isLoading) {
      dispatch(setIsFilterLoading(true));
    }
  }, [isLoading]);
  return {
    updateFilter: mutate,
  };
};

export const useGetMMVDetails = () => {
  const { mutate, data, isError, isSuccess, error, reset } = useMutation(
    (data) => api.post("vehicle/detail", data)
  );

  const errList = errorHandler(error, data);

  return {
    sendRegistrationNo: mutate,
    mmvData: data?.data?.return_data || [],
    isSuccess: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    dataMaster: data,
    resetMaster: reset,
  };
};

export const getTraceIdFromSegmentSlug = (segment_slug) => {
  const { setUrlQuery } = useUrlQuery();
  const navigate = useNavigate();

  const { data } = useQuery(
    ["trace_id_from_segment_slug", segment_slug],
    () => {
      return api.post(`/generate_trace_id`, {
        segment_slug,
      });
    },
    {
      onSuccess: (res) => {
        navigate(
          {
            search: setUrlQuery("trace_id", res?.data?.return_data?.trace_id),
          },
          {
            replace: true,
          }
        );
      },
    }
  );
  return {
    trace_id: data?.data?.return_data?.trace_id,
    segment_name: data?.data?.return_data?.segment_name,
  };
};

export const useGetPreviousDataForRenewal = ({
  previous_policy_no,
  registration_no,
  segment_slug,
  policy_type,
}) => {
  const policy_no_ref = useRef();
  const registration_no_ref = useRef();
  const dispatch = useDispatch();
  const memorisedDependancy = () => {
    if (policy_no_ref.current) {
      if (policy_no_ref.current === previous_policy_no)
        return previous_policy_no;
    }
    if (registration_no_ref.current) {
      if (registration_no_ref.current === registration_no)
        return registration_no;
    }
    if (previous_policy_no?.length >= 6) return previous_policy_no;
    if (registration_no?.length >= 10) return registration_no;
  };

  const { data, isSuccess, isError, error, reset, mutate } = useMutation(
    () => {
      return api.post(`/generate_renewal_data`, {
        previous_policy_no,
        registration_no,
        segment_slug,
        policy_type,
      });
    },

    {
      onSuccess: (res) => {
        policy_no_ref.current =
          res?.data?.return_data?.claim_details?.previous_policy_no;
        registration_no_ref.current =
          res?.data?.return_data?.registration_details?.registration_no;
        dispatch(
          setSelectedAddons({
            ...res?.data?.return_data?.addons,
          })
        );
        dispatch(
          setGeographicalExtention(
            res?.data?.return_data?.geographical_extension
          )
        );
        dispatch(
          setelectricalAccessories({
            entries:
              res?.data?.return_data?.accessories?.electrical_accessories,
            update: true,
          })
        );
        dispatch(
          setnonElectricalAccessories({
            entries:
              res?.data?.return_data?.accessories?.non_electrical_accessories,
            update: true,
          })
        );
        dispatch(setBiFuel(res?.data?.return_data?.accessories?.bi_fuel));
        if (res?.data?.return_data?.discounts) {
          const discounts = res?.data?.return_data?.discounts;
          dispatch(
            setDiscountCheckboxes({
              anti_theft_discount: discounts?.anti_theft,
              handicap_discount_discount: discounts?.handicap_discount,
              aa_membership_discount: discounts?.aa_membership,
              confine_to_site_discount: discounts?.confine_to_site,
              tppd_cover_discount: discounts?.tppd_cover,
            })
          );
          dispatch(setConfineToSiteDetails(discounts?.confine_to_site_details));
        }
      },
    }
  );
  const errList = errorHandler(error, data);
  useEffect(() => {
    if (
      [RENEWAL, ROLL_OVER].includes(policy_type) &&
      (previous_policy_no?.length >= 6 || registration_no?.length >= 10)
    ) {
      mutate();
    }
  }, [memorisedDependancy()]);
  return {
    isRenewalDataFetched: isSuccess && data?.data?.status !== 500,
    isError: isError,
    alertMaster: errList,
    renewalData: data?.data?.return_data,
    isSuccess: isSuccess,
    resetRenewalData: reset,
    fetchRenewalData: mutate,
  };
};

export const useGetVoluntaryExcessAmountFromSegmentSlug = (payload) => {
  const { data } = useQuery(["voluntary_excess_amount", payload], () => {
    return api.post("voluntary_excess/list", {
      segment_slug: payload,
    });
  });
  return {
    voluntary_excess_amounts: data?.data?.return_data || [],
  };
};

export const getPreviousAddonsListFromSegmentSlug = (segment_slug) => {
  const { data } = useQuery(
    ["previous_addons_list_from_segment_slug", segment_slug],
    () => {
      return api.post(`/previous_policy_addon`, {
        segment_slug,
      });
    }
  );
  return {
    previous_addons_items: data?.data?.return_data,
  };
};

export const useGetEmiCoverCountListFromSegmentSlug = (payload) => {
  const { data } = useQuery(["emi_cover_counts", payload], () => {
    return api.post("emi_list", {
      segment_slug: payload?.segment_slug,
      ic_id: payload?.ic_id,
      addon_configurator_id: payload?.addon_configurator_id,
    });
  });
  return { emi_cover_counts: data?.data?.return_data || [] };
};

export const useGetRtoListFromRtoCode = (rto_code, dependancy) => {
  const { data, isSuccess, isError, error } = useQuery(
    ["rto_from_rto_code", dependancy],
    () => {
      return api.post("/rto/list_by_reg_no", {
        registration: rto_code,
      });
    }
  );
  return {
    rto_items: data?.data?.return_data || [],
    error: error?.response?.data?.errors?.registration,
    registration_no_valid: isSuccess,
    registration_no_not_valid: isError,
  };
};
